import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import EventList from "../../components/EventList";
import Event from "../../components/Event";
import Provider from "../../context";

function Router({url}) {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/event/:occurrenceId">
        <Event />
        </Route>
        <Route path="/:cats">
          <Provider url={url}><EventList /></Provider>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
