import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
export const ApiDataContext = React.createContext({});

function ApiDataProvider({ children, url }) {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const { cats } = useParams();

    useEffect(() => {
        const categories = cats.split(',');
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                     setData(result.filter(obj => {
                        let value = 0;
                        categories.map(category => {
                            if(obj.Labels?.includes(category)) {
                                value = 1;
                            }
                            return null;
                         });
                         return (value === 1);
                     }));
                },
                (error) => {
                    setError(error);
                }
            )
        }, [url, cats])
        
    return (
        <ApiDataContext.Provider value={{ data, error }}>
            {children}
        </ApiDataContext.Provider>
    )
}

export default ApiDataProvider;