import React from "react";
import ApiDataProvider from "./ApiDataContext";

function Provider({ children, url }) {
  
  return (
      <ApiDataProvider url={url}>
          {children}
      </ApiDataProvider>
  );
}

export default Provider;
