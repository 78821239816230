const weekDayNames = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"];
    
const monthNames = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];

const monthLongNames = ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"];

export const getDay = (date) => {
    const eventDate = new Date(date);
    return eventDate.getDate();
}

const getWeekDay = (date) => {
    const eventDate = new Date(date);
    return weekDayNames[eventDate.getDay()];
}

export const getMonth = (date) => {
    const eventDate = new Date(date);
    return monthNames[parseInt(eventDate.getMonth())];
}

const longDateAndTime = (startDate, endDate) => {
    const eventStartDate = new Date(startDate);
    const eventEndDate = new Date(endDate);

    const month = monthLongNames[parseInt(eventStartDate.getMonth())].toLowerCase();
    const weekDayName = getWeekDay(eventStartDate);
    const day = getDay(eventStartDate);
    const year = eventStartDate.getFullYear();
    const startTimeHours = eventStartDate.getHours().toString().padStart(2, '0');
    const startTimeMinutes = eventStartDate.getMinutes().toString().padStart(2, '0');
    const endTimeHours = eventEndDate.getHours().toString().padStart(2, '0');
    const endTimeMinutes = eventEndDate.getMinutes().toString().padStart(2, '0');
    
    return `${weekDayName} d. ${day}. ${month} ${year} kl. ${startTimeHours}:${startTimeMinutes} - ${endTimeHours}:${endTimeMinutes}`;
}

export default longDateAndTime;