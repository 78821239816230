import React from "react";
import "./App.css";
import Router from "./routing/Router";

function App({url}) {
  return (
    <>
      <Router url={url} />
    </>
  );
}

export default App;
